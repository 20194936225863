/* Body */
body {
  margin: 0;
}

/* List */
ol,
ul,
li {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
ul {
  list-style: none;
}

/* Text */
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
